import { createVNode, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

import { EventManagement, DialingType, StatusAgent } from "@/app/shared/utils/enums";
import { useJssip } from "@/app/shared/composables/useJssip";
import { registerEventManagement, transferTicket } from "@/app/shared/services";
import { CallMode } from "@/packages/vue-jssip/jssip/phone";
import { SupervisedUser } from "@/@types/supervision";

type UserStatusType = typeof StatusAgent;

interface UserEvent {
  menu: number;
  state: UserStatusType[keyof UserStatusType];
  call_ticket?: number;
  open_ticket?: number;
}

export function useCaptureTicket() {
  const store = useStore();
  const router = useRouter();

  // /**
  //  * @type {JssipManager}
  //  */
  const $jssip = useJssip();

  const userData = computed(() => store.getters["getDataUser"]);
  const globalState = computed(() => store.getters["getStateUser"]);
  const dataUserState = computed(() => store.getters["getDataUserState"]);

  const handleCapture = (record: SupervisedUser, isCall = false) => {
    Modal.confirm({
      title: `¿Estás seguro de capturar el ticket del usuario “${record.user.fullname}”?`,
      content: "Recuerda que se te redirigirá al módulo de Workspace para poder atenderlo.",
      icon: createVNode(QuestionCircleOutlined),
      onOk: async () => {
        const ticketId = isCall ? record.last_ticket : record.last_open_ticket?.id;
        const channelId = isCall ? 1 : record.last_open_ticket?.channel.id;
        const interactionId = isCall
          ? record.active_call?.last_call_interaction.id
          : record.last_open_ticket?.last_message_interaction_id;
        const clientId = isCall
          ? record.active_call?.client?.id
          : record.last_open_ticket?.client.id;

        const body = {
          tickets: [ticketId],
          user: userData.value.id,
        };
        await store.dispatch("tranferTicketAgent", body);

        console.log("interaction captured", interactionId);
        store.dispatch("setCaptureTicket", true);
        router.push({
          path: "/workspace",
          query: { ticket: ticketId, interaction: interactionId, client: clientId },
        });

        // const userEvent = isCall ? EventManagement.Talking : EventManagement.Opened;
        const userEvent = isCall ? EventManagement.Talking : EventManagement.Online;

        const eventBody: UserEvent = {
          menu: 30,
          state: dataUserState.value,
        };
        eventBody[isCall ? "call_ticket" : "open_ticket"] = ticketId;

        store.dispatch("updateGlobalState", userEvent);
        await registerEventManagement(globalState.value, eventBody);

        if (isCall) {
          $jssip.reset();
          $jssip.setCallMode(CallMode.Captured);
          $jssip._call(
            `*75${record.annex.split("@")[0]}`,
            null,
            null,
            {
              id: record.last_ticket,
              client: record.active_call?.client,
              portfolio: { id: record.portfolio },
            },
            DialingType.Manual,
            userData.value.id
          );
        }

        await transferTicket({
          customer_id: userData.value.customer.id,
          user_id: record.user.id,
          name: userData.value.fullname,
          ticket: ticketId,
          channel: channelId,
        });
      },
      centered: true,
      okText: "Capturar",
      cancelText: "Cancelar",
    });
  };

  return handleCapture;
}
