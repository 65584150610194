const getters = {
  getAllIndustries(state) {
    return state.industries.map((industry, index) => {
      return {
        id: index + 1,
        name: industry,
      };
    });
  },
};

export default getters;
