<template>
  <a-drawer
    v-if="ticketClientData.id"
    class="ticket-history-drawer"
    placement="right"
    :title="drawerTitle"
    :width="drawerOptions.drawerWidth"
    :open="true"
    :body-style="drawerBodyStyle"
    :header-style="{
      width: drawerOptions.drawerWidth,
    }"
    @close="handleCloseDrawer"
  >
    <a-spin :spinning="isLoading">
      <div class="drawer-content">
        <!-- SECCION IZQ DEL DRAWER -->
        <a-space
          class="drawer-content__left-section"
          :style="{ width: drawerOptions.innerSectionsWidth }"
          direction="vertical"
          :size="16"
        >
          <!--campaña asociada-->
          <div>
            <p class="drawer-divs--title">Campaña asociada:</p>
            <p class="text mrg-bottom-0">{{ ticketClientData?.portfolio?.name }}</p>
          </div>

          <!--canales interactuados-->
          <div>
            <p class="drawer-divs--title">Canales interactuados:</p>
            <div v-if="ticketClientData.interactions?.length" class="order__image">
              <div class="channels">
                <div class="channel__ticker">
                  <div v-for="group in interactionsIconList" :key="group.at(0)?.channel?.id">
                    <a-popover :title="`${setPopoverTitle(group.at(0).channel)} del contacto`">
                      <template #content>
                        <ul>
                          <li v-for="interaction in group" :key="interaction.id">
                            {{ interaction?.identifier }}
                          </li>
                        </ul>
                      </template>

                      <img
                        v-if="group.at(0)?.channel?.icon"
                        :src="group.at(0)?.channel?.icon"
                        alt="icono"
                        style="width: 19px; margin-left: 5px"
                      />
                    </a-popover>
                  </div>
                </div>
              </div>
            </div>
            <p v-else class="text__user">Este ticket no cuenta con interacciones.</p>
          </div>

          <!--usuarios asignados-->
          <div>
            <p class="drawer-divs--title">Usuarios que lo atendieron:</p>
            <a-space v-if="ticketClientData.users?.length > 0" direction="vertical" :size="8">
              <div v-for="user in ticketClientData.users" :key="user.id">
                <div class="user-name">
                  <div
                    v-if="user.picture"
                    class="name__logo"
                    :style="`background-image: url('${user.picture}')`"
                  ></div>
                  <div v-else>
                    <div
                      class="container__image"
                      :class="user?.role.name === 'Agente' ? 'background__agent' : ''"
                    >
                      <img
                        class="user__icon"
                        :src="user?.picture"
                        v-if="user?.picture"
                        alt="perfil"
                      />
                      <img
                        v-else
                        class="user__profile"
                        :class="user?.role.name === 'Agente' ? 'user__agente' : ''"
                        :src="getProfileIcon(user.icon || iconDefault)"
                        height="16"
                        width="16"
                        alt="perfil"
                      />
                    </div>
                  </div>
                  <span class="text">{{ user.fullname }}</span>
                </div>
              </div>
            </a-space>

            <div v-if="ticketClientData.users?.length < 1">
              <span class="text__user">Este ticket no fue asignado a un usuario. </span>
            </div>
          </div>
          <!--Correo asociado-->
          <div v-if="emailAssociated !== ''">
            <p class="drawer-divs--title">Correo asociado:</p>
            <a-button type="link" class="btn-link" @click="showEmailHistoryDrawerConfig = true">
              <p class="text mrg-bottom-0 email"><LinkOutlined /> Correo # {{ emailAssociated }}</p>
            </a-button>
          </div>

          <!--ultima tipificacion-->
          <div>
            <p v-if="ticketClientData?.last_management" class="drawer-divs--title">
              Ultima tipificación:
            </p>
            <div v-if="ticketClientData?.last_management" class="last_management">
              <p class="mrg-bottom-4">
                Grupo: <span>{{ ticketClientData.last_management?.group_name }}</span>
              </p>
              <p class="mrg-bottom-4">
                Resultado: <span> {{ ticketClientData.last_management?.result_name }}</span>
              </p>
              <p v-if="ticketClientData?.last_management?.motive_name" class="mrg-bottom-4">
                Motivo: <span>{{ ticketClientData?.last_management?.motive_name }}</span>
              </p>
              <p v-if="ticketClientData?.last_management?.submotive_name" class="mrg-bottom-4">
                Submotivo: <span> {{ ticketClientData?.last_management?.submotive_name }} </span>
              </p>
              <p class="mrg-bottom-4">
                Comentario:
                <span>
                  {{
                    ticketClientData?.last_management?.observ_01
                      ? ticketClientData?.last_management?.observ_01
                      : " -- "
                  }}
                </span>
              </p>
            </div>
          </div>

          <!--etiquetas-->
          <div v-if="ticketClientData?.tags?.length > 0">
            <p class="drawer-divs--title">Etiquetas:</p>
            <div v-if="ticketClientData?.tags?.length > 0" class="container__tags">
              <p v-for="item in ticketClientData?.tags" :key="item.id">
                <input class="color-tag" :style="getTagColor(item.color)" />{{ item.name }}
              </p>
            </div>
          </div>

          <!--comentario del ticket-->
          <div>
            <div class="drawer-divs--title">Comentario del ticket:</div>
            <p class="text" v-if="ticketClientData?.observations?.length > 0">
              {{ ticketClientData?.observations }}
            </p>
            <p class="text__user" v-if="ticketClientData?.observations === null">
              Este ticket no cuenta con comentario.
            </p>
          </div>

          <!--Lista de variables capturadas-->
          <div v-if="nameChatbot">
            <p class="drawer-divs--title">
              Variables capturadas por el chatbot "{{ nameChatbot }}"
            </p>
            <ul class="drawer-divs__list-vars">
              <li
                v-for="(varItem, index) in ticketClientData?.ticket_variables"
                :key="`var_${index}`"
              >
                <span>{{ varItem.bot_variable.variable.name }}:</span>
                <span class="text--secondary">{{ varItem.value }}</span>
              </li>
            </ul>
          </div>
        </a-space>

        <!-- SECCION DER DEL DRAWER -->
        <section
          v-if="channelInteractionList.length"
          class="drawer-content__right-section"
          :style="{ width: drawerOptions.innerSectionsWidth }"
        >
          <!-- ------- FIXED SECTION -->
          <div
            class="identifier-select-section"
            :class="canGenerateConclusionAI ? '' : 'container-ai'"
          >
            <div class="select-channel">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-if="channelInteractionList.length"
                v-model:value="currentChannelId"
                @change="handleChannelChange(currentChannelId)"
              >
                <a-select-option v-for="ch in channelInteractionList" :key="ch.id">
                  <!-- OPCIONES SI ES TELEFONIA -->
                  <div v-if="ch?.channel?.id === Voice || ch?.channel?.id === VoiceInbound">
                    <div class="select-channel__flex-center">
                      <img
                        class="select-channel__alias-icon"
                        :src="imageChannel(ch?.channel?.name)"
                        alt="flag"
                      />
                      <span
                        >Llamadas
                        <small class="select-channel__mini-label">al {{ ch.identifier }}</small>
                      </span>
                    </div>
                    <div class="select-channel__flex-center">
                      <UserIcon class="select-channel__user-icon" />
                      <span>
                        {{ ch.identifier }}
                        <small class="select-channel__mini-label">nro. del contacto</small>
                      </span>
                    </div>
                  </div>

                  <!-- OPCIONES SI ES MENSAJERIA -->
                  <div v-else>
                    <div class="select-channel__flex-center">
                      <img
                        class="select-channel__alias-icon"
                        :src="imageChannel(ch?.channel?.name)"
                        :alt="`Icono de {{ ch?.channel?.name }}`"
                      />
                      <span v-if="ch?.channel_customer.alias">
                        {{ ch?.channel_customer.alias }}
                      </span>
                      <span v-else>
                        {{ ch.identifier }}
                        <small class="select-channel__mini-label">sin alias</small>
                      </span>
                    </div>
                    <div class="select-channel__flex-center">
                      <UserIcon class="select-channel__user-icon" />
                      <span>
                        {{ ch.identifier }}
                        <small
                          v-if="ch?.channel?.id !== Messenger && ch?.channel?.id !== Instagram"
                          class="select-channel__mini-label"
                          >nro. del contacto</small
                        >
                      </span>
                    </div>
                  </div>
                </a-select-option>
              </a-select>
            </div>

            <!-- IDENTIFIER FIXED TAG -->
            <div class="fixed-tag-identifier">
              <div class="fixed-tag-identifier__flex">
                <img
                  class="fixed-tag-identifier__identifier-icon"
                  :src="imageChannel(channelSelectedData?.channel?.name)"
                  :alt="`Icono de ${channelSelectedData?.channel?.name}`"
                />
                <p v-if="!isVoiceInteraction">{{ channelSelectedData?.channel_customer?.alias }}</p>
                <p v-else>Llamadas</p>
              </div>
              <span class="fixed-tag-identifier__divider"></span>
              <div class="fixed-tag-identifier__flex">
                <UserIcon class="fixed-tag-identifier__user-icon" />
                <p>{{ channelSelectedData?.identifier }}</p>
              </div>
            </div>

            <AIConclusionButton
              v-if="isAdmin && !hasSummaries && hasIntegration && canGenerateConclusionAI"
              type="history"
              :ticketManagerId="ticketClientData?.id"
              :interactionManagerId="interactionManagerId"
            />
            <AIConclusionTextArea
              v-if="showSummary"
              type="history"
              :conclusion="summaryManagement[0].summary"
              :summaryId="summaryManagement[0].id"
              :username="summaryManagement[0].user.fullname"
            />
          </div>

          <CustomScrollbar v-if="currentChannelId" class="scroll-section">
            <!-- COMPONENTES DETALLE INTERACCION -->
            <WorkspaceCallHistory
              v-if="isVoiceInteraction"
              :isRenderInDrawer="true"
              :ticketId="ticketClientData?.id"
              :identifier="channelSelectedData?.identifier"
            />

            <div class="scroll-section__messages" v-else>
              <WorkspaceTicketInformation
                v-if="interactionMessagesDetail"
                :ticket="interactionMessagesDetail"
                type="start"
              />
              <WorkspaceMessageItem
                v-for="(message, index) in interactionMessages"
                :key="message.id"
                :message="message"
                :nextMessage="interactionMessages?.at(index + 1)"
              />
            </div>
          </CustomScrollbar>
        </section>
      </div>
    </a-spin>

    <div class="ticket-history-drawer__footer">
      <a-button @click="handleCloseDrawer">Ocultar</a-button>
    </div>
  </a-drawer>

  <DetailDrawer
    v-if="showEmailHistoryDrawerConfig"
    :emailId="emailAssociated"
    @onCloseDrawer="showEmailHistoryDrawerConfig = false"
  />
</template>

<script setup>
import { ref, toRefs, onMounted, computed, reactive } from "vue";
import { groupBy } from "lodash";
import { setChannelTitle } from "@/app/ticketsManager/utils/groupBy";
import { ticketService } from "@/app/workspace/services/TicketService";
import { ChannelType, ProfileTypes } from "@/app/shared/utils/enums";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import WorkspaceCallHistory from "@/app/workspace/components/cards/WorkspaceCallHistory.vue";
import WorkspaceTicketInformation from "@/app/workspace/components/chat/WorkspaceTicketInformation.vue";
import WorkspaceMessageItem from "@/app/workspace/components/chat/WorkspaceMessageItem.vue";
import AIConclusionButton from "@/app/integrations/components/buttons/AIConclusionButton.vue";
import DetailDrawer from "@/app/workspace/components/drawers/email/DetailDrawer.vue";
import UserIcon from "@/app/shared/components/icons/UserIcon.vue";
import { useStore } from "vuex";
import AIConclusionTextArea from "@/app/integrations/components/inputs/AIConclusionTextArea.vue";
import { RolTypes } from "@/app/shared/utils/enums";
import EventBus from "@/app/shared/utils/eventBus";
import { integrationManager } from "@/app/integrations/services/IntegrationManager";
import { LinkOutlined } from "@ant-design/icons-vue";

import inboundChatImage from "@/app/contacts/assets/inbound-chat.svg?url";
import wppDisabledImage from "@/app/contacts/assets/icon-whatsapp-disabled.svg?url";
import messengerImage from "@/app/contacts/assets/messenger.svg?url";
import phoneImage from "@/app/contacts/assets/phone.svg?url";

const store = useStore();

// PROPS
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  contactInfo: {
    type: Object,
  },
});

const { item, contactInfo } = toRefs(props);

EventBus.on("updateChatHistory", () => {
  getInteractionsMessages(currentChannelId.value);
});

// DATA
const ticketClientData = ref({}); // obj
const interactionManagerId = ref(0);
const channelInteractionList = ref([]); // arr de obj
const hasSummaries = ref(false);
const hasIntegration = ref(false);
const showSummary = ref(false);
const summaryManagement = ref([]);

const currentChannelId = ref(null); // num
const channelSelectedData = ref(null); // obj

const isLoading = ref(false); // bool
const recordInfo = ref(undefined);
const interactionMessages = ref([]); // arr de obj
const interactionMessagesDetail = ref(null); // obj

const showEmailHistoryDrawerConfig = ref(false);

const drawerOptions = reactive({});

const iconDefault = "smile.svg";
const userIcon = "userIcon.svg";

// CHANNEL TYPE ENUM
const { Voice, VoiceInbound, Messenger, Instagram, Sms } = ChannelType;
const voiceInteractionIdList = [Voice, VoiceInbound];
const channelGenerateConclusionAI = [Voice, VoiceInbound, Sms];

const emit = defineEmits(["onCloseDrawerTicket"]);

const isAdmin = computed(() => {
  const dataUser = store.getters["getDataUser"];

  return dataUser?.role?.id !== RolTypes.agent;
});

const getProfileIcon = (profile) => {
  return new URL(`../../../../users/assets/profiles/${profile}`, import.meta.url).href;
};

const getRecordTicketClient = async () => {
  isLoading.value = true;
  try {
    await store.dispatch("fetchRecordTicketClient", item.value.id);
    const { voice, messages } = await ticketService.getInteractionsChannels(item.value.id);

    ticketClientData.value = store.getters["getRecordTicketClient"];
    interactionManagerId.value = ticketClientData.value.interactions[0].id;

    const uniqueIdentifierInteractionsList = voice.filter(
      (i, idx, arr) => idx === arr.findIndex((j) => i.identifier === j.identifier)
    );

    channelInteractionList.value = [...uniqueIdentifierInteractionsList, ...messages];

    const hasInteractions = channelInteractionList.value.length >= 1;

    drawerOptions.drawerWidth = hasInteractions ? "700px" : "390px";
    drawerOptions.innerSectionsWidth = hasInteractions ? "50%" : "100%";

    if (channelInteractionList.value.length) {
      channelSelectedData.value = channelInteractionList.value.at(0);
      currentChannelId.value = channelInteractionList.value.at(0).id;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const canGenerateConclusionAI = computed(() => {
  return !channelGenerateConclusionAI.includes(channelSelectedData.value?.channel?.id || 0);
});

const isVoiceInteraction = computed(() => {
  const channelSelected = channelInteractionList.value.find(
    (item) => item.id === currentChannelId.value
  );

  return voiceInteractionIdList.includes(channelSelected?.channel?.id || 0);
});

const getInteractionsMessages = async (id) => {
  if (!isVoiceInteraction.value && channelInteractionList.value.length) {
    isLoading.value = true;
    try {
      console.log(await ticketService.getInteractionMessages(id), "THIS ONEEEEE");

      const data = await ticketService.getInteractionMessages(id);

      if (!data) {
        return [];
      }

      const messages = data.messages || [];
      const summaries = data.summaries || [];
      summaryManagement.value = summaries.filter((summary) => summary.origin === "management");
      const summaryWorspace = summaries.filter((summary) => summary.origin === "workspace");

      if (summaryWorspace.length > 0) {
        hasSummaries.value = true;
      }

      if (summaryManagement.value.length > 0) {
        hasSummaries.value = true;
        showSummary.value = true;
      }

      const messageHistory = [
        ...messages.map((message) => ({ ...message, type: "message" })),
        ...summaryWorspace.map((summary) => ({ ...summary, type: "summary" })),
      ];

      const sortedMessages = messageHistory.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      interactionMessages.value = sortedMessages;
      interactionMessagesDetail.value = await ticketService.getDetails(ticketClientData.value.id);
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  }
};

// MOUNTED
onMounted(async () => {
  await getRecordTicketClient();
  await getInteractionsMessages(currentChannelId.value);
  await fetchIntegration();
});

// SELECT
const handleChannelChange = async (chId) => {
  channelSelectedData.value = channelInteractionList.value.find((e) => e.id === chId);
  await getInteractionsMessages(currentChannelId.value);
};

// DYNAMIC PROPS COMPONENTS
const drawerBodyStyle = {
  padding: "0px",
  marginTop: "55px",
  height: "calc(100% - 55px)",
};

const drawerTitle = computed(() => {
  return `Ticket ID: #${ticketClientData.value.id}`;
});

const interactionsIconList = computed(() => {
  const grouped = groupBy(
    ticketClientData.value?.interactions,
    (interactions) => interactions.channel?.icon
  );

  return Object.values(grouped);
});

const emailAssociated = computed(() => {
  const flatInteractions = interactionsIconList.value.flat();
  const ticketEmail = flatInteractions.find((item) => item.channel?.name === "EMAIL");

  if (ticketEmail) {
    return ticketEmail.identifier;
  }
  return "";
});

const nameChatbot = computed(() => {
  if (!ticketClientData.value.ticket_variables || !ticketClientData.value.ticket_variables.length)
    return;
  return ticketClientData.value.ticket_variables[0].bot_variable.bot.name;
});

const fetchIntegration = async () => {
  const response = await integrationManager.getCustomerSetting();
  hasIntegration.value = response.enable_summary;
};

const setPopoverTitle = (channel) => {
  return setChannelTitle(channel?.icon);
};

const handleCloseDrawer = () => {
  emit("onCloseDrawerTicket");
  store.dispatch("resetRecordTicket");
};

const getTagColor = (color) => {
  return `background-color: #${color}; width: "30px"}`;
};

const imageChannel = (name) => {
  let url = "";
  if (name === "Chat Inbound") {
    url = inboundChatImage;
  } else if (name === "WhatsApp") {
    url = wppDisabledImage;
  } else if (name === "Messenger") {
    url = messengerImage;
  } else if (name === "Instagram") {
    url = inboundChatImage;
  } else {
    url = phoneImage;
  }
  return url;
};
</script>

<style lang="sass">
.ticket-history-drawer
  .ant-drawer-header
    position: fixed
    overflow-y: visible
    z-index: 1

  .ant-drawer-header-title
    flex-direction: row-reverse

  .ant-drawer-close
    padding: 0
    margin: 0
</style>

<style lang="sass" scoped>
.ant-spin-nested-isLoading, :deep(.ant-spin-container)
  height: 100%
.ticket-history-drawer
  &__footer
    position: absolute
    right: 0
    bottom: 0
    width: 100%
    height: 53px
    border-top: 1px solid #e9e9e9
    padding: 10px 16px
    background: #fff
    text-align: right
    z-index: 1

.drawer-content
  display: flex

  &__left-section
    padding: 30px 80px 20px 31px

  &__right-section
    border-left: solid $gray-5

.drawer-divs
  padding-bottom: 35px

  &--title
    font-weight: 600
    color: #262A31
    font-size: 14px
    margin-bottom: 8px

  &__list-vars
    padding-left: 24px

.text
  font-size: 14px
  color: $gray-8
  overflow: hidden
  text-wrap: nowrap
  text-overflow: ellipsis
.email
  color: #1677FF

.text--secondary
  color: $text-secondary

.channels
  display: flex
  margin-bottom: 5px

  img
    width: 19px
    margin-right: 8px
    margin-top: 8px

.user-name
  display: flex
  align-items: center

.last_management
  p
    color: $gray-9
    font-size: 13px
    span
      color: $gray-7
      font-size: 13px

.container__tags
  margin-top: 10px
  margin-bottom: 10px

.color-tag
  width: 30px
  height: 8px
  border-radius: 30px
  margin-right: 10px
  border: $white
  margin-bottom: 4px

.icon-empty
  padding: 4px 8px
  display: flex
  text-align: center
  background: #FFF7E6
  border-radius: 50%
  height: 33px
  margin-top: 5px
  margin-right: 5px
  width: 33px
.name
  display: flex
  align-items: center
  position: relative

  &__logo
    display: block
    width: 22px
    height: 22px
    background-size: cover
    background-position: center
    border-radius: 50%
    margin-right: 5px

    &--empty > img
      width: 25px
      height: 25px
      border-radius: 50%

.text__user
  color: $gray-6
  font-style: italic
  margin-bottom: 3px
  margin-top: 2px

.user__icon
  width: 22px
  height: 22px
  margin: 6px
  border-radius: 50%

.user__profile
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.user__agente
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.separator
  margin-bottom: 15px

.icono__default
  width: 19px

.container__image
  width: 20px
  height: 20px
  background-color: rgb(230, 247, 255)
  border-radius: 50%
  margin-right: 6px
  display: flex
  align-items: center
  justify-content: center

.background__agent
  background: #FFF6E2
  border-radius: 50%
  margin-right: 6px

.order__image
  display: flex
  align-items: center

.channel__ticker
  display: flex
  justify-content: space-around

// ------- RIGHT SECTION CLASSES
.identifier-select-section
  width: 100%
  margin-top: 12px
  margin-bottom: 30px

  position: sticky
  z-index: 1
  .ant-form-item
    margin: 0

.container-ai
  margin-bottom: 10px

.select-channel
  padding: 0 30px

  .ant-select
    width: 100%

  &__flex-center
    display: flex
    align-items: center
    gap: 3px

  &__alias-icon
    margin-right: 4px
    width: 12.5px

  &__user-icon
    width: 17px
    color: $gray-8

  &__mini-label
    color: $text-tertiary
    font-weight: 100

.fixed-tag-identifier
  margin-top: 8px
  padding: 8px
  min-height: 38px

  display: grid
  grid-template-columns: 1fr 1px 1fr
  align-items: center
  Justify-items: center

  background-color: $base-ninja-100
  border-top: 1px solid #00000026
  border-bottom: 1px solid #00000026

  p
    margin: 0
    overflow: hidden
    text-overflow: ellipsis

  &__divider
    width: 1px
    height: 100%
    background-color: #00000026

  &__flex
    display: flex
    align-items: center
    gap: 8px


  &__identifier-icon
    height: 16px

  &__user-icon
    color: $gray-8

.scroll-section
  height: 76.5vh

  &__messages
    padding: 0 24px

.btn-link
  margin: 0
  padding: 0
</style>
