import httpManager from "@/app/shared/utils/httpManager";
import { tokenManager } from "@/app/shared/utils/tokenManager";

const initialState = () => {
  const access_token = tokenManager.getToken();

  if (access_token) httpManager?.setHeader();
  return { access_token, workspaces: [] };
};

const state = initialState();

export default state;
