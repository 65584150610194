const initialState = () => ({
  industries: [
    "Supermercados",
    "Retail",
    "Ropa y moda",
    "Comidas y bebidas",
    "Banca",
    "Seguros",
    "Servicios Financieros",
    "Fintech",
    "Contact Center",
    "Software",
    "Educación",
    "Entretenimiento",
    "Mobiliario",
    "Gobierno",
    "Turismo",
    "Hoteles",
    "Importación y exportación",
    "Automotriz",
    "Tecnologías de la información",
    "Comercio",
    "Servicios jurídicos",
    "Logística",
    "Marketing",
    "Minería",
    "Medios de comunicación",
    "Ecommerce",
    "BPO",
    "Restaurantes",
    "Delivery",
    "Telecomunicaciones",
    "Transporte",
    "Otros",
  ],
});

const state = initialState();

export default state;
