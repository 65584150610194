import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtain a ticket from a call
 * @params {number} number
 * @params {object} params
 */

// This is used in outbound calls and predective
export async function fetchTicketByPhone(number, params) {
  const body = { phone_number: number };
  if (params?.client) body.client = params.client;
  if (params?.asterisk_id) body.asterisk_id = params.asterisk_id;
  if (params?.strategy_phone_id) body.strategy_phone_id = params.strategy_phone_id;
  if (params?.type_dialing) body.type_dialing = params.type_dialing;
  if (params?.code_phone) body.code_phone = params.code_phone;
  if (params?.portfolio) body.portfolio = params.portfolio;
  if (params?.lead_id) body.lead_id = params.lead_id;
  const response = await httpManager.post("/workspace/outbound-call/", body);
  return response.data;
  // return {
  //   id: data?.ticket?.id,
  //   client: data?.ticket?.client,
  //   channel: data?.ticket?.channel?.id,
  //   channel_person: data?.channel_person?.id,
  //   time_acw: data?.ticket?.time_acw,
  //   portfolio: data?.ticket?.portfolio,
  //   type_dialing: data?.ticket?.type_dialing,
  // };
}
