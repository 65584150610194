import httpManager from "@/app/shared/utils/httpManager";

export async function fetchTabEmailContact(
  // clientId,
  clientId,
  pagination = {
    current: 1,
    pageSize: 30,
  }
) {
  const tablePageSize = 30;

  const offset = (pagination?.current - 1 || 0) * tablePageSize;
  const pageSize = pagination?.pageSize || tablePageSize;

  // const url = `/workspace/tickets/${clientId}/email_messages/?offset=${offset}&limit=${pageSize}`;
  const url = `/contacts/clients/${clientId}/email_messages/?offset=${offset}&limit=${pageSize}`;
  const { data } = await httpManager.get(url);
  const results = data.results.map((el) => ({
    ...el,
    created_at: el.last_email_message.created_at,
    identifier: el.last_email_message.channel_customer.identifier,
    email_messages_associated: el.email_threads_associated,
  }));
  return { ...data, results: results };
}
