import {
  CompanyName,
  ProductName
} from "@/app/shared/utils/constants";

export const channelsConfig = [{
    id: 1,
    name: "WhatsApp",
    type: "WhatsApp",
    channelName: "WhatsApp",
    button: "Integrar nuevo",
    description: "Automatiza las notificaciones y comunícate con tus clientes a través del API de WhatsApp Business.",
    status: "Prepago",
    namecolor: "blue",
    link: "/channels/setup/whatsapp",
  },
  {
    id: 2,
    name: "Web Chat",
    type: "Chat Inbound",
    channelName: "Chat Inbound",
    button: "Añadir a tu web",
    description: "Amplia tu comunicación digital agregando un livechat a tus páginas web.",
    status: "Gratis",
    namecolor: "green",
    link: "/channels/setup/inbound",
  },
  {
    id: 5,
    name: "Voz",
    type: "Telefonica",
    channelName: "Telefonia Entrante",
    button: "Integrar nuevo",
    description: `Activa números entrantes (DID). Para llamadas salientes, usa la telefonía de ${CompanyName} o de otro proveedor.`,
    status: "Prepago",
    namecolor: "blue",
    link: "/channels/setup/voice",
  },
  {
    id: 3,
    name: "Facebook Messenger",
    type: "Messenger",
    channelName: "Messenger",
    button: "Conectar nuevo",
    description: "Conversa con tus clientes por el chat de la red social más usada en el mundo.",
    status: "Gratis",
    namecolor: "green",
    link: "/channels/setup/messenger",
  },
  // {
  //   id: 4,
  //   name: "SMS",
  //   type: "SMS",
  //   button: "Activar",
  //   description: "Conversa con tus clientes por uno de los canales celulares de mayor demanda.",
  //   status: "Prepago",
  //   namecolor: "blue",
  //   link: "/channels/setup/sms",
  // },
  {
    id: 6,
    name: "Instagram",
    type: "Instagram",
    channelName: "Instagram",
    button: "Integrar API de Instagram",
    description: "Chatea por la red donde cada vez más se están comercializando productos.",
    status: "Gratis",
    namecolor: "green",
    link: "/channels/setup/instagram",
  },
  {
    id: 7,
    name: "Correo",
    type: "Correo",
    channelName: "EMAIL",
    button: "Conecta tu correo electrónico",
    description: "Envía y personaliza correos masivos, además de responder de manera individual.",
    status: "Prepago",
    namecolor: "blue",
    link: "/channels/setup/correo",
  },
  {
    id: 4,
    name: "SMS",
    type: "SMS",
    button: "Ya integrado",
    description: "Envía mensajes efectivos y con alta apertura. Garantiza que tus comunicados lleguen a destino.",
    status: "Prepago",
    namecolor: "blue",
    link: "/channels/setup/sms",
  },
];

export const whatsappProviders = [
  // {
  //   id: 2,
  //   type: "gupshup",
  //   description: [
  //     "Pago por mensajes enviados y/o recibidos ($0.001 Perú)",
  //     "No hay un mínimo de consumo y solo pagas por lo que usas.",
  //     "Se pueden enviar fotos ,videos, audios, localizaciones y más.",
  //     "Se puede usar un celular de Perú como WhatsApp business.",
  //     "Ideal para negocios que recién empiezan por este canal.",
  //   ],
  // },
  {
    id: 4,
    type: "securitec",
    title: `Integrar con ${CompanyName} partner de Gupshup`,
    description: [
      "Ideal para pequeñas y medianas empresas.",
      "Solo pagas por los mensajes que consumes.",
      "Precios más bajos. Desde $0.003 multidestino.",
      "Puedes compartir fotos, audios, videos y documentos.",
    ],
  },
  // {
  //   id: 1,
  //   type: "twilio",
  //   title: "Integrar con twilio",
  //   description: [
  //     "Conecta la API de WhatsApp creada en Twilio.",
  //     "Solo pagas por los mensajes que consumes. ",
  //     "Precios de mensajes dependiendo el destino.",
  //     "$0.0100 por mensaje de plantillas según el volumen",
  //   ],
  // },
];

export const ColorInbount = [{
    name: "Rojo",
    code: "#E00E14",
  },
  {
    name: "Rosado - 1",
    code: "#FE3B75",
  },
  {
    name: "Rosado - 2",
    code: "#FE75A0",
  },
  {
    name: "Morado - 1",
    code: "#F612E1",
  },
  {
    name: "Morado - 2",
    code: "#E676F6",
  },
  {
    name: "Morado - 3",
    code: "#B64CE3",
  },
  {
    name: "Morado - 4",
    code: "#904FA9",
  },
  {
    name: "Morado - 5",
    code: "#7045F8",
  },
  {
    name: "Azul - 1",
    code: "#002CDE",
  },
  {
    name: "Azul - 2",
    code: "#76A8FB",
  },
  {
    name: "Azul - 3",
    code: "#2E8CD4",
  },
  {
    name: "Azul - 4",
    code: "#2C77AB",
  },
  {
    name: "Azul - 5",
    code: "#1890FF",
  },
  {
    name: "Azul - 6",
    code: "#13B3CC",
  },
  {
    name: "Verde - 1",
    code: "#20B393",
  },
  {
    name: "Verde - 2",
    code: "#2EC66A",
  },
  {
    name: "Amarillo",
    code: "#F8D432",
  },
  {
    name: "Marrón - 1",
    code: "#740407",
  },
  {
    name: "Marrón - 2",
    code: "#A57331",
  },
  {
    name: "Naranja- 1",
    code: "#FE6E20",
  },
  {
    name: "Naranja- 2",
    code: "#E14919",
  },
  {
    name: "Gris - 1",
    code: "#ACB4BA",
  },
  {
    name: "Gris - 2",
    code: "#5B6B78",
  },
  {
    name: "Gris - 3",
    code: "#434F59",
  },
];

export const colorSelectorList = [{
    name: "black",
    code: "#262A31",
  },
  {
    name: "blue",
    code: "#109FEE",
  },
  {
    name: "golden purple",
    code: "#9254DE",
  },
  {
    name: "geek blue",
    code: "#597EF7",
  },
  {
    name: "magenta",
    code: "#EB2F96",
  },
  {
    name: "dust red",
    code: "#F5222D",
  },
  {
    name: "sunset orange",
    code: "#FA8C16",
  },
  {
    name: "sunrise yellow",
    code: "#FADB14",
  },
  {
    name: "polar green",
    code: "#52C41A",
  },
];

export const channelTablecolumns = [{
    title: "Canal",
    dataIndex: "channel_name",
    key: "channel_name",
    slots: {
      customRender: "channel"
    },
    width: 220,
  },
  {
    title: "Identificador",
    dataIndex: "associate",
    key: "associate",
    slots: {
      customRender: "associate"
    },
  },
  {
    title: "Alias",
    dataIndex: "alias",
    info: `Nombre que se le dará al identificador en ${ProductName} para que puedas reconocerlo fácilmente en cualquier módulo. El alias únicamente será modificable para el canal de Webchat y Voz.`,
    // customRender: ({ text }) => text || "-",
    slots: {
      customRender: "alias"
    },
  },
  {
    title: "Proveedor",
    dataIndex: "provider_name",
    key: "provider_name",
    width: 160,
  },
  {
    title: "Usuario que agregó",
    dataIndex: "adder_user",
    key: "adder_user",
    slots: {
      customRender: "adderUser"
    },
    ellipsis: true,
  },
  {
    dataIndex: "created_at",
    key: "created_at",
    slots: {
      title: "createdAtTitle",
      customRender: "createdAt"
    },
    sorter: (a, b) => {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
    width: 170,
  },
  {
    dataIndex: "status",
    key: "status",
    slots: {
      title: "statusTitle",
      customRender: "status"
    },
    sorter: (a, b) => {
      if (a.status < b.status) return -1;
      if (a.status > b.status) return 1;
      return 0;
    },
    width: 120,
  },
  {
    title: "Acciones",
    key: "action",
    slots: {
      customRender: "actionScoped"
    },
    width: 180,
    fixed: "right",
  },
];

export const channelStatus = {
  active: 1,
  inactive: 2,
  pending: 4,
  reject: 5,
};

export const whatsappTemplateTableColumns = [{
    title: "N°",
    dataIndex: "id",
    key: "id",
    width: 60,
  },
  {
    title: "Nombre de la plantilla",
    dataIndex: "name",
    key: "name",
    width: 180,
    ellipsis: true,
  },
  {
    title: "Nombre de la visualización",
    dataIndex: "display_name",
    key: "display_name",
    width: 180,
    ellipsis: true,
  },
  {
    title: "Categoría",
    dataIndex: "category",
    key: "category",
    width: 140,
  },
  {
    title: "Tipo de plantilla",
    dataIndex: "template_type",
    key: "template_type",
    slots: {
      customRender: "template_type"
    },
    width: 140,
  },
  {
    title: "Colas asociadas",
    dataIndex: "queues",
    key: "queues",
    slots: {
      customRender: "queues"
    },
    width: 140,
  },
  {
    title: "Estado",
    dataIndex: "provider_state",
    key: "provider_state",
    slots: {
      customRender: "state"
    },
    width: 100,
  },
  {
    title: "Activo",
    dataIndex: "status",
    key: "status",
    slots: {
      customRender: "status"
    },
    width: 100,
  },
  {
    title: "Acciones",
    slots: {
      customRender: "actions"
    },
    width: 80,
  },
];

export const smsTemplatesColumns = [{
    title: "N°",
    width: 60,
    customRender: ({
      index
    }) => index + 1,
  },
  {
    title: "Nombre de plantilla",
    dataIndex: "name",
  },
  {
    title: "Campañas asociadas",
    dataIndex: "portfolios",
    key: "portfolios",
  },
  {
    title: "Colas asociadas",
    dataIndex: "queues",
    key: "queues",
  },
  {
    title: "Activo",
    dataIndex: "status",
    key: "active",
  },
  {
    title: "Acciones",
    key: "actions",
  },
];