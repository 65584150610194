<template>
  <label class="picker" :class="getPickerClass">
    <div class="picker__container" @dragover="handleDragOver" @drop="handleDrop">
      <div class="picker__content">
        <SmileOutlined v-if="hasFile" class="picker__icon" size="large" />
        <FolderOpenOutlined v-else class="picker__icon" size="large" />
        <span class="picker__text picker__text__types picker__text--title" v-if="!hasFile">
          Sube aquí tu archivo
        </span>
        <span class="picker__text picker__text__types" v-if="!hasFile">
          {{ validTypesText }}.
        </span>
        <span class="picker__text"> {{ pickerText }} </span>
        <LoadingOutlined v-if="uploading" />
        <span v-if="hasFile" class="picker__text">{{ fileName }}</span>
      </div>

      <div @click.prevent="handleClear" class="clear-btn">
        <CloseOutlined v-if="hasFile || error.type || error.size" class="clear-btn__icon" />
      </div>
    </div>

    <input
      ref="input"
      type="file"
      @change.self="handleFileChange"
      :accept="allTypes ? '' : supportedTypes.join(',')"
      :disabled="disabled"
    />
  </label>
</template>

<script>
import { defineComponent } from "vue";
import {
  CloseOutlined,
  SmileOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";

import { uploadFile } from "@/app/shared/services";

const OneMegaByte = 1000000;

export default defineComponent({
  components: {
    CloseOutlined,
    SmileOutlined,
    FolderOpenOutlined,
    LoadingOutlined,
  },
  props: {
    value: {
      required: false,
      type: [File, null, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    supportedTypes: {
      type: Array,
      default: () => ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    },
    maxMBSize: {
      type: Number,
      default: null,
    },
    allTypes: {
      type: Boolean,
      default: false,
    },
    preUpload: {
      type: Boolean,
      default: false,
    },
    uploadToEmail: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: "",
    },
    modalKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: {
        type: false,
        size: false,
      },
      uploading: false,
    };
  },
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(file) {
        this.$emit("update:value", file);
        this.$refs.input.value = null;
      },
    },
    hasFile() {
      return !!this.file;
    },
    getPickerClass() {
      return {
        "picker--error": this.error.type || this.error.size,
        "picker--uploading": this.uploading,
        "picker--ready": this.hasFile,
        "picker--disabled": this.disabled,
      };
    },
    getIconType() {
      return this.hasFile ? "smile" : "folder-open";
    },
    fileName() {
      return this.file?.name;
    },
    pickerText() {
      if (this.error.type || this.error.size) {
        const errorText = [];

        if (this.error.size) errorText.push(`El peso excede el permitido de ${this.maxMBSize}MB`);
        if (this.error.type) errorText.push(`El tipo de archivo no es correcto`);

        return errorText.join(" y ");
      }

      return this.hasFile
        ? "Archivo listo"
        : this.maxMBSize
        ? `(Max. ${this.maxMBSize}MB)`
        : "Arrastre su archivo aquí";
    },
    validTypesText() {
      if (this.allTypes) return "";

      let text = "Solo se acepta ";

      if (this.supportedTypes.join("").includes("sheet")) text = text + "XLSX";
      else {
        const typesList = this.supportedTypes
          .map((type) => type.replaceAll(".", ""))
          .join(", ")
          .toUpperCase();
        text = text + typesList;
      }

      return text;
    },
    // supportedTypes() {
    //   return ["text/xlsx"];
    // },
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0];
      await this.handleFile(file);
    },
    async handleFile(file) {
      // if (!this.validateType(file)) return;

      this.resetError();

      console.log(file);

      const isValidType = this.isValidType(file);
      const isOverMaxSize = this.maxMBSize && file.size > this.maxMBSize * OneMegaByte;

      if (isOverMaxSize || !isValidType) {
        if (!isValidType) this.error.type = true;
        if (isOverMaxSize) this.error.size = true;
        return;
      }

      let urlData = {};

      if (this.preUpload) {
        urlData = await this.preUploadFile(file);
        this.$emit("update:url", urlData.file);
      }
      if (this.uploadToEmail) {
        urlData = await this.uploadEmailImage(file);
        this.$emit("update:url", urlData.public_url);
      }

      this.file = file;
    },
    /**
     * @param {File} file
     */
    async preUploadFile(file) {
      let urlData = null;

      this.uploading = true;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const splittedName = file.name.split(".");

        const fileData = {
          file: file,
          name: `${this.inputName}-${this.modalKey}.${splittedName.at(-1)}`,
        };

        urlData = await uploadFile(fileData, `/workspace/managements/upload-file/`);
      } catch (error) {
        console.error(error);
        this.$message.error("No se pudo subir el archivo");
      }
      this.uploading = false;

      return urlData;
    },
    async uploadEmailImage(file) {
      let urlData = null;

      this.uploading = true;
      try {
        const fileData = {
          file,
        };

        urlData = await uploadFile(fileData, `/mail/attachment/`);
      } catch (error) {
        console.error(error);
        this.$message.error("No se pudo subir el archivo");
      }
      this.uploading = false;

      return urlData;
    },
    isValidType(file) {
      if (!file) return false;
      if (this.allTypes) return true;

      const type = file.type?.split("/")?.[1] || "";

      console.log({ file, type });

      if (!type) return false;

      let isSupportedType = this.supportedTypes.join(",").includes(type);

      if (!isSupportedType) {
        const extension = file.name.split(".")?.at(-1);

        isSupportedType = this.supportedTypes.join(",").includes(extension);
      }

      return isSupportedType;
    },
    resetError() {
      this.error.size = false;
      this.error.type = false;
    },
    handleClear() {
      this.file = null;
      this.resetError();
      this.$emit("update:url", undefined);
    },
    handleDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    async handleDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      await this.handleFile(file);
    },
  },
});
</script>

<style lang="sass" scoped>
.picker
  user-select: none

  &__container
    cursor: pointer
    height: 140px
    color: $gray-8
    font-size: 12px
    border-radius: 8px
    margin: 4px 0
    border: 1px dashed $gray-6
    position: relative
    transition: all 0.2s ease

    // width: 552px

  &__content
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  &__icon
    font-size: 36px
    margin-bottom: 8px

  &__text
    font-size: $font-small
    text-align: center

    &--title
      font-size: $font-normal
      font-weight: 600

  &--error &__container
    color: $red-6
    background-color: $red-8
    border: 1px dashed $red-6

  &--uploading &__container
    color: $blue-7
    background-color: $blue-1
    border: 1px dashed $blue-7

  &--ready &__container
    color: $green-6
    background-color: $green-9
    border: 1px dashed $green-6

  &--ready &__text
    &:nth-child(2)
      font-weight: 600
      font-size: $font-normal

  &--disabled &__container
    color: $text-disabled
    background-color: $gray-2
    border: 1px dashed $gray-4
    transition: all 0.2s ease
    pointer-events: none

.clear-btn
  height: 25px
  width: 25px
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  font-size: 14px
  top: 12px
  right: 12px
  z-index: 10
  border-radius: 100%
  transition: all ease 0.3s

  &:hover
    background-color: #dbffcc

  &__icon
    transition: all ease 0.3s

    &:hover
      transform: scale(1.2)

label input[type="file"]
  display: none
</style>
