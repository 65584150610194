<template>
  <div class="actions">
    <a-popover
      v-model:visible="showEmojiPopover"
      :title="null"
      overlayClassName="emoji-popover"
      trigger="click"
      placement="topLeft"
      arrowPointAtCenter
    >
      <ActionButton class="box" type="emoji" @click="showEmojiPopover = true" />

      <template #content>
        <EmojiPicker
          native
          hide-search
          hide-group-icons
          disable-skin-tones
          :group-names="groupNames"
          @select="handleSelectEmoji"
        />
      </template>
    </a-popover>

    <label for="file-input">
      <ActionButton :disabled="Boolean(file)" class="box" type="file" prevent-event />

      <input
        ref="fileInputRef"
        id="file-input"
        type="file"
        :disabled="Boolean(file)"
        :accept="supportedFileTypes.join()"
        @change="handleFileInputChange($event)"
      />
    </label>

    <label for="media-input">
      <ActionButton :disabled="Boolean(file)" class="box" type="media" prevent-event />

      <input
        ref="mediaInputRef"
        id="media-input"
        type="file"
        :disabled="Boolean(file)"
        :accept="supportedMediaTypes.join()"
        @change="handleFileInputChange($event)"
      />
    </label>
  </div>

  <div class="file" v-if="file">
    <p>
      <PaperClipOutlined style="font-size: 18px" />

      <span class="name">{{ getFileName(file) }}</span>
    </p>

    <a-button type="link" class="button" danger @click="handleDelete">
      <DeleteOutlined />
    </a-button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { message } from "ant-design-vue";
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

import { Emoji } from "@/@types/workspace";
import { groupNames } from "@/app/workspace/utils/emojiPickerOptions";
import {
  supportedMediaTypes,
  supportedFileTypes,
} from "@/app/workspace/components/chat/input/supportedFiles";

import ActionButton from "@/app/workspace/components/chat/input/ActionButton.vue";

const OneMegaByte = 1_000_000;

defineProps<{ file: string | File | undefined }>();

const emit = defineEmits<{
  selectEmoji: [emoji: string];
  "update:file": [file: File | undefined];
}>();

const showEmojiPopover = ref(false);

const fileInputRef = ref<HTMLInputElement>();
const mediaInputRef = ref<HTMLInputElement>();

const getFileName = (file: File | string) => {
  if (typeof file === "string") return file.split("/").at(-1);

  return file.name;
};

const handleSelectEmoji = (emojiMetadata: Emoji) => {
  const emojiChar = emojiMetadata.i;
  emit("selectEmoji", emojiChar);
  showEmojiPopover.value = false;
};

const handleFileInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const selectedFile = target.files?.[0];

  try {
    validateFile(selectedFile);
    emit("update:file", selectedFile);
  } catch (error) {
    console.error(error);
    message.error(error as string);
  }
};

const validateFile = (file: File | undefined) => {
  if (!file) throw "No se seleccinó ningun archivo";

  if (file.size > 5 * OneMegaByte) throw "Supera el tamaño máximo de 5MB";
};

const handleDelete = () => {
  emit("update:file", undefined);
  if (fileInputRef.value) fileInputRef.value.value = "";
  if (mediaInputRef.value) mediaInputRef.value.value = "";
};
</script>

<style scoped lang="sass">
.button
  padding: 0
  height: 22px

.actions
  display: flex
  align-items: center
  gap: 4px
  margin-top: 8px

#file-input, #media-input
  display: none

:deep(.box)
  border-radius: 4px
  background-color: $gray-3
  overflow: hidden

:deep(.box img)
  width: 18px

.file
  display: flex
  justify-content: space-between
  align-items: center
  background-color: $geekblue-1
  border-radius: 4px
  padding: 0 8px
  margin-top: 12px
  color: $gray-8

  p
    margin: 0
    display: flex
    align-items: center
    gap: 4px

  .name
    color: #4096FF
</style>

<style>
.emoji-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.v3-emoji-picker {
  box-shadow: none !important;
}

.v3-emoji-picker.v3-color-theme-light .v3-footer {
  display: none;
}
</style>
