<template>
  <div class="workspace" :class="{ 'with-banner': showBanner }">
    <WorkspaceFirstPanel
      class="column-1"
      ref="firstPanelRef"
      @update:ticket="fetchTicket({ preventLoading: true })"
    />
    <WorkspaceSecondPanel
      class="column-2"
      :key="`${route.query.ticket}-${route.query.interaction}`"
      @update:ticket="fetchTicket({ preventLoading: true })"
    />
    <WorkspaceThirdPanel class="column-3" :key="route.query.client" />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  provide,
  watch,
  computed,
  reactive,
  onBeforeUnmount,
  onMounted,
  nextTick,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";

import { fetchListTags } from "@/app/workspace/services";
import { ticketService } from "@/app/workspace/services/TicketService";
import { useSocket } from "@/app/shared/composables/useSocket";
import { useJssip } from "@/app/shared/composables/useJssip";
import { SentType } from "@/@types/ivr/sent";

import WorkspaceFirstPanel from "@/app/workspace/components/sections/WorkspaceFirstPanel.vue";
import WorkspaceSecondPanel from "@/app/workspace/components/sections/WorkspaceSecondPanel.vue";
import WorkspaceThirdPanel from "@/app/workspace/components/sections/WorkspaceThirdPanel.vue";

import { registerEventManagement } from "@/app/shared/services";
import { EventManagement } from "@/app/shared/utils/enums";
import { getDataLoggedUser } from "@/app/settings/services";

const route = useRoute();
const router = useRouter();
const store = useStore();
const jssip = useJssip();

const showBanner = inject("showBanner");

// Filters
const filterState = reactive({
  search: "",
  channel: "",
  queue: "",
});
const workspaceDataSourceLocal = reactive({
  tags: [],
});

provide("filterState", filterState);

// Workspace logic
const isTicketLoading = ref(false);
provide("isTicketLoading", isTicketLoading);

const ticketsListLoading = ref(false);
provide("ticketsListLoading", ticketsListLoading);

const ticket = ref(null);
const ticketInteractionsChannels = ref([]);

const isInCall = computed(() => jssip.isInPhoneCall);

watch([isInCall, () => ticket.value?.portfolio], () => {
  console.log("WATCHER PARA SETEAR EL IVR");
  if (!isInCall.value) {
    jssip.setMeta("ivr", null);
    return;
  }

  const ticketPortfolio = ticket.value?.portfolio;

  if (ticketPortfolio && jssip.phone.isCurrentCallTicket(route.query.ticket))
    jssip.setMeta("ivr", {
      ...ticketPortfolio,
      shouldSent: ticketPortfolio?.survey_submission_type === SentType.Automatic,
    });
});

const fetchTicket = async ({ preventLoading = false } = {}) => {
  const ticketId = route.query.ticket;

  if (!preventLoading) isTicketLoading.value = true;

  try {
    console.log("fetchTicket", { route, ticketId, param: route.query.ticket });
    if (ticketId) ticket.value = await ticketService.getDetails(ticketId);
  } catch (error) {
    console.error(error);
    message.error("Ha ocurrido un error");
  }

  isTicketLoading.value = false;
};

const fetchInteractionsChannels = async () => {
  const ticketId = route.query.ticket;

  if (!ticketId) return;

  // const interactions = await ticketService.getInteractionsChannels(ticketId);
  // ticketInteractionsChannels.value = [...interactions.messages, ...interactions.voice];

  try {
    await store.dispatch("fetchInteractionsChannels", ticketId);
    const interactions = store.getters["getInteractionsChannel"];
    ticketInteractionsChannels.value = [
      ...interactions.messages.filter((el) => el.channel?.name != "EMAIL"),
      ...interactions.voice,
    ];
  } catch (error) {
    console.error(error);
  }
};

watch(
  [() => route.query.ticket, () => route.query.interaction],
  async () => {
    const currentTicketId = Number(route.query.ticket);

    if (ticket.value?.id !== currentTicketId) await fetchTicket();

    await fetchInteractionsChannels();
  },
  { immediate: true }
);

const interaction = computed(() => {
  const selectedInteractionId = Number(route.query.interaction);

  const currentInteraction = ticketInteractionsChannels.value.find(
    (interaction) => interaction.id === selectedInteractionId
  );

  return currentInteraction;
});

const { vueSocket } = useSocket();

vueSocket.subscribe("refreshChannels", async (data) => {
  if (!route.path.includes("workspace")) return;

  console.log("refresh channels", data);
  await fetchInteractionsChannels();

  if (!data.interaction_id) return;
  if (!data.is_call && route.query.interaction && data.interaction_id !== route.query.interaction)
    return;

  if (data.is_call) {
    jssip.setMeta("interaction", data.interaction_id);
    store.dispatch("fetchActiveWorkspaceTickets");
  }

  console.log(data.interaction_id, "interactionId 8");
  router.push({
    path: "/workspace",
    query: {
      // ticket: data.ticket_id ?? route.query.ticket,
      ticket: route.query.ticket,
      interaction: data.interaction_id,
      client: route.query.client,
    },
  });
});

const workspaceDataSource = computed(() => {
  return {
    tags: workspaceDataSourceLocal.tags,
  };
});

watch(interaction, () => {
  store.commit("SET_LAST_OPENED_TICKET", {
    ticket: ticket.value,
    interaction: interaction.value,
    client: route.query.client,
  });
});

provide("ticketInteractionsChannels", ticketInteractionsChannels);
provide("selectedTicket", ticket);
provide("selectedChannel", interaction);
provide("fetchTicket", fetchTicket);

// HOOKS
onBeforeUnmount(() => store.commit("SET_TICKETS_FILTERS", {}));

const dataUserState = computed(() => {
  return store.getters["getDataUserState"];
});

onMounted(async () => {
  await loadInitialData();
  const customerData = await getDataLoggedUser();
  console.log("customerData.enable_enter", customerData);
  localStorage.setItem("messageSendOption", customerData.enable_enter);
  if (route.query.ticket) {
    const { voice, messages } = await ticketService.getInteractionsChannels(route.query.ticket);
    if (messages.length > 0) {
      setTimeout(async () => {
        await registerEventManagement(EventManagement.Opened, {
          open_ticket: ticket.value.id,
          menu: 23,
          state: dataUserState.value,
        });
        store.dispatch("setEventMessaging", EventManagement.Opened);
      }, 3000);
    }
  }
});

const loadInitialData = async () => {
  ticketsListLoading.value = true;
  await store.dispatch("fetchActiveWorkspaceTickets");
  const response = await fetchListTags();
  workspaceDataSourceLocal.tags = response;
  ticketsListLoading.value = false;
};

provide("workspaceDataSource", workspaceDataSource);
</script>

<style lang="sass" scoped>
.workspace
  height: calc(100vh - #{$header-height})
  background-color: $gray-5
  display: grid
  grid-template-columns: repeat(24, 1fr)
  // grid-template-columns: minmax(4fr, 4.1fr) minmax(4fr, 4.6fr) minmax(0, 10fr)
  // gap: 1px

  &__panel
    &--bordered
      border-left: $border-medium
      border-right: $border-medium

.with-banner
  height: calc(100vh - #{$header-height} - 35px)

.column-1
  grid-column: span 5

.column-2
  grid-column: span 8

.column-3
  grid-column: span 11
</style>
